import { template as template_bb98e58fff1f4ca1ad5226b5b233ed7d } from "@ember/template-compiler";
const FKControlMenuContainer = template_bb98e58fff1f4ca1ad5226b5b233ed7d(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
