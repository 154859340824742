import { template as template_3a840088589a407e81fe30da22ccfead } from "@ember/template-compiler";
const FKLabel = template_3a840088589a407e81fe30da22ccfead(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
