import { template as template_dc2532f5533645449d5cfa1c14eb01c0 } from "@ember/template-compiler";
const WelcomeHeader = template_dc2532f5533645449d5cfa1c14eb01c0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
