import { template as template_fe9d2e8850704499a5a64e6c70a41e43 } from "@ember/template-compiler";
const SidebarSectionMessage = template_fe9d2e8850704499a5a64e6c70a41e43(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
